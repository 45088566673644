export const routesWithNewSidebar = [
	"#/services-account",
	"#/enki",
	"#/invitations",
	"#/invitations/load-csv",
	"#/search-bitoria",
	"#/search-bitoria/results",
	"#/prisma",
	"#/offers",
	"#/offers/new",
	"#/solar-monitoring",
	"#/makita",
	"#/reading-records",
	"#/reading-records/detail",
	"#/reading-records/xm-report",
	"#/frontiers",
	"#/smartbits",
	"#/fustigations",
	"#/admins-directory",
	"#/admins-directory/detail",
	"#/estimations",
	"#/measurement-point",
	"#/conversion",
	"#/electricity-supply-service/attention",
	"#/states",
	"#/notifications-history",
	"#/notifications-history/settings",
	"#/meters",
	"#/chat",
	"#/frontiers-report",
	"#/orders",
	"#/users-companies",
	"#/records",
	"#/documents",
	"#/viability-diagnostic",
];
