import { jwtDecode } from "jwt-decode";
import { registerApplication, start } from "single-spa";
import {
	constructApplications,
	constructLayoutEngine,
	constructRoutes,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { routesWithNewSidebar } from "./utils/RoutesWithSidebar";

const isTokenExpired = (token) => {
	try {
		const decodedToken = jwtDecode(token);
		const currentTime = Math.floor(Date.now() / 1000);

		if (decodedToken.exp && decodedToken.exp < currentTime) {
			return true;
		}
		return false;
	} catch (error) {
		console.error("Invalid token", error);
		return true;
	}
};

const isAuthenticated = () => {
	const authInfo = localStorage.getItem("authInfo");
	if (!authInfo) {
		return false;
	}

	try {
		const authInfoObj = JSON.parse(authInfo);
		const authToken = authInfoObj.refresh_token;
		if (!authToken || isTokenExpired(authToken)) {
			return false;
		}

		return true;
	} catch (error) {
		console.error("Error parsing authInfo", error);
		return false;
	}
};

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
	routes,
	loadApp({ name }) {
		if (name !== "@enerBit/login" && !isAuthenticated()) {
			window.location.href = "/";
			return Promise.resolve(() => null);
		}
		if (name === "@enerBit/login" && isAuthenticated()) {
			window.location.href = `/#${
				localStorage.getItem("saveURL") || "/search-bitoria"
			}`;
			return Promise.resolve(() => null);
		}
		if (!name) {
			return Promise.resolve(() => null);
		}
		return System.import(name);
	},
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

registerApplication({
	name: "@enerBit/sidebar",
	app: (_) => System.import("@enerBit/sidebar"),
	activeWhen: (location) =>
		routesWithNewSidebar.some((route) => location.hash.includes(route)),
});

start({
	urlRerouteOnly: false,
});
